<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card
      :v-if="plan"
      class="cardWrapper"
    >
      <div
        class="paymentPromptWrapper"
        :class="!isProcessing && !isComplete? 'show': 'hide'"
      >
        <div class="modalHeader">
          <button
            class="dismissBtn"
            @click="close"
          >
            Cancel
          </button>
        </div>
        <div class="infoWrapper">
          <h3>Transaction Amount</h3>
          <p class="amountLabel">
            {{ plan.priceLabel }}
          </p>
          <h4>Plan Details</h4>
          <p>{{ plan.primaryHeading }}</p>
        </div>
        <div class="stripeFormWrapper">
          <div class="cardHeader">
            <img
              src="https://pngimg.com/uploads/credit_card/credit_card_PNG126.png"
              alt=""
              class="cardIcon"
            >
            Provide your card details
          </div>
          <div class="stripeInnerWrapper">
            <card
              ref="stripeCard"
              class="stripe-card"
              :class="{ complete }"
              :stripe="stripeKey"
              :options="stripeOptions"
            />
          </div>
          <!-- <Elements stripe="{stripePromise}">
          <CheckoutForm
            on-success="{(e)"
          >
            setCurrentView("successView")}
            />
          </checkoutform>
        </Elements> -->
          <button
            class="pay-with-stripe"
            @click="pay"
          >
            Pay with credit card
          </button>
          <div class="poweredBy">
            Powered By
            <img
              src="https://img.icons8.com/color/452/stripe.png"
              alt=""
            >
          </div>
        </div>
        <img
          src="https://cdn.iconscout.com/icon/free/png-256/mobile-online-secure-payment-electronic-transaction-2-5912.png"
          alt=""
          class="transactionIcon"
        >
      </div>
      <div
        :class="isProcessing && !isComplete? 'show': 'hide'"
        class="paymentPromptWrapper"
      >
        <div class="processingPayment">
          <div class="loader">
            Loading...
          </div>
          <p>Please wait</p>
        </div>
      </div>
      <div
        :class="!isProcessing && isComplete? 'show': 'hide'"
        class="paymentPromptWrapper"
      >
        <div class="successPayment">
          <img
            src="https://thumbs.gfycat.com/TatteredValidAiredale-max-1mb.gif"
            alt=""
          >
          <p>Transaction Success</p>
        </div>
      </div>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import AuthService from '@/services/AuthService'
  import { Card, createToken } from 'vue-stripe-elements-plus'
  const auth = new AuthService()

  export default {
    name: 'StripePaymentDialog',
    components: { Card },
    props: {
      isVisible: {
        type: Boolean,
        default: true,
      },
      plan: {
        type: Object,
        default: null,
      },
      selectedPlanType: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      user: null,
      complete: false,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      isFormDirty: false,
      snackbar: false,
      notification: 'Unknown',
      isProcessing: false,
      isComplete: false,
    }),

    watch: {
      isVisible (val) {
        val || this.close()
        if (val) {
          this.$refs.stripeCard.clear()
        }
      },
    },

    async created () {
      this.user = await auth.getUser()
      this.usremail = localStorage.getItem('username')
    },

    methods: {
      close () {
        this.$emit('hide-payment-dialog')
      },
      async pay () {
        this.isProcessing = true
        try {
          const stripeResponse = await createToken()
          if (stripeResponse.error) {
            this.isProcessing = false
            this.notification = stripeResponse.error.message
            this.snackbar = true
          } else {
            await this.onPaymentSuccess(stripeResponse)
            this.isComplete = true
            this.isProcessing = false
            setTimeout(() => {
              this.$emit('on-payment-success')
            }, 3000)
          }
        } catch (error) {
          this.isProcessing = false
          this.notification = 'Unable to process your payment'
          this.snackbar = true
        }
      },
      async onPaymentSuccess (stripePayload) {
        const paymentPayload = {
          stripetoken: stripePayload.token.id,
          amount: this.plan.price,
          ratetierid: this.plan.rateTierId,
          transid: 0,
        }
        const { data: transactionResponse } = await this.httpConnector.makeRequest('post', `${this.apiEndpoint}processtransaction?code=${this.apiCode}`, paymentPayload)
        const balancePayload = {
          stripetoken: '0',
          amount: this.plan.price,
          ratetierid: this.plan.rateTierId,
          transid: transactionResponse[0].transactionID,
        }
        await this.httpConnector.makeRequest('post', `${this.apiEndpoint}user/updatebalance?code=${this.apiCode}`, balancePayload)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .stripe-card {
    width: 300px;
    // border: 1px solid grey;
    input {
      font-size: 1.5rem!important;
    }
  }
  .stripe-card.complete {
    border-color: green;
  }
  .paymentPromptWrapper {
  position: relative;
  height: 100%;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  .modalHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgb(255, 255, 255);

    .dismissBtn {
      background-color: rgb(250, 44, 44);
      border: none;
      border-bottom-left-radius: 8px;
      color: white;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 4px 35px;
      cursor: pointer;
    }
  }

  .infoWrapper {
    min-height: 220px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 20px 30px;
  }

  .amountLabel {
    font-weight: 600;
    font-size: 2.2rem;
  }

  background-color: whitesmoke;

  h4 {
    margin-top: 30px;
    margin-bottom: 5px;
    font-size: 22px;
  }

  .stripeFormWrapper {
    .stripeFormInnerWrapper {
      padding: 20px 40px 20px 40px;
    }

    .cardHeader {
      display: flex;
      padding-left: 40px;
      padding-top: 40px;
      justify-content: flex-start;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;

      .cardIcon {
        margin-right: 14px;
        width: 30px;
      }
    }

    .poweredBy {
      position: absolute;
      right: 40px;
      font-size: 0.8rem;
      color: rgb(194, 194, 194);
      padding-left: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 17px;
        width: 40px;
      }
    }
  }

  .pay-with-stripe {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: rgb(59, 206, 49);
    color: white;
    font-size: 1.1rem;
    padding: 15px;
    text-transform: uppercase;
    box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: rgb(36, 187, 25);
    }

    &.processing {
      cursor: progress;
      background-color: rgb(36, 187, 25);
    }
  }

  .transactionIcon {
    position: absolute;
    top: 120px;
    right: 30px;
    width: 80px;
  }

  .processingPayment {
    height: 100%;
    width: 100%;
    // background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 1.3rem;
    }
  }

  .successPayment {
    height: 100%;
    width: 100%;
    // background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;

    img {
      width: 40%;
    }

    p {
      font-size: 1.3rem;
    }
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.2s infinite ease-in-out;
    animation: load7 1.2s infinite ease-in-out;
  }

  .loader {
    color: #ffffff;
    font-size: 10px;
    margin: -20px auto 30px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }

  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .loader:after {
    left: 3.5em;
  }

  @-webkit-keyframes load7 {

    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  @keyframes load7 {

    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  .FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #7795f8;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #829fff;
    border-radius: 4px;
  }

  .FormRow {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #819efc;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

  .errorText {
    margin-top: 6px;
    height: 15px;
    margin-bottom: -19px;
    color: red;
  }
}
.cardWrapper {
  height: 500px;
}
.stripeInnerWrapper {
  padding: 30px 40px 10px 40px;
}
</style>
